import React, { useRef, useState, useEffect, ReactNode, forwardRef } from 'react';
import Typography, { TypographyProps } from 'components/ui-components-v2/Typography';
import Tooltip from 'components/ui-components-v2/Tooltip';

interface TruncatedTextWithTooltipProps extends TypographyProps {
    placement?: 'top' | 'bottom' | 'left' | 'right';
    children: ReactNode;
    dataCy?: string;
}

const TruncatedTextWithTooltip = forwardRef<HTMLSpanElement, TruncatedTextWithTooltipProps>(({ children, placement, dataCy, ...typographyProps }, ref) => {
    const textRef = useRef<HTMLSpanElement | null>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const element = textRef.current;

        const checkTruncation = () => {
            if (element) {
                setIsTruncated(element.scrollWidth > element.clientWidth);
            }
        };

        checkTruncation(); // Initial check in case ResizeObserver doesn't trigger

        const observer = new ResizeObserver(checkTruncation);
        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [children]);

    return (
        <Tooltip title={children as string} disableHoverListener={!isTruncated} placement={placement}>
            <Typography
                {...typographyProps}
                noWrap
                ref={(node: HTMLSpanElement) => {
                    textRef.current = node;
                    if (typeof ref === 'function') {
                        ref(node);
                    } else if (ref) {
                        (ref as React.MutableRefObject<HTMLSpanElement | null>).current = node;
                    }
                }}
                data-cy={dataCy}
                style={{ ...typographyProps.style }}>
                {children}
            </Typography>
        </Tooltip>
    );
});

TruncatedTextWithTooltip.displayName = 'TruncatedTextWithTooltip';

export default TruncatedTextWithTooltip;
