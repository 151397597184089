import Icon from '@mui/material/Icon';
import React from 'react';
import classNames from 'classnames';
import Translation from 'components/data/Translation';
import IconButton from 'components/ui-components-v2/IconButton';
import Tooltip from 'components/ui-components-v2/Tooltip';
import './styles/main.scss';

interface Props {
    className?: string;
    size?: 'small';
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ResetOverwriteButton = ({ className, size, onClick }: Props): JSX.Element => {
    return (
        <Tooltip title={Translation.get('labels.formatHasOverwrites')} placement="top">
            <IconButton size="small" onClick={onClick}>
                <Icon
                    className={classNames(className, 'ui-v2-cape-revert-overwrite-button__overwrite', {
                        'ui-v2-cape-revert-overwrite-button__overwrite--small': size === 'small'
                    })}>
                    history
                </Icon>
            </IconButton>
        </Tooltip>
    );
};

export { ResetOverwriteButton };
