import React from 'react';
import { IconButton } from '@mui/material';
import Format from 'types/format.type';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
import './styles/main.scss';

interface Props {
    formats: Format[];
    selectedFormats: string[];
    removeFormatSelection: (key: Format['key'][]) => void;
}

const FormatChips = ({ formats, selectedFormats, removeFormatSelection }: Props) => {
    const newSelectedFormats = formats.filter((format) => selectedFormats.includes(format.key));

    return (
        <div className="ui-v2-format-chips">
            {newSelectedFormats.length > 0 && newSelectedFormats[0].key !== 'general' && (
                <>
                    <div className="ui-v2-format-chips__alert">
                        {newSelectedFormats.length > 0 &&
                            Translation.get('sidebarRight.formats.editingFormats', 'template-designer', {
                                selectedFormats: newSelectedFormats.length,
                                formatsCount: formats.length
                            })}
                        <IconButton
                            className="ui-v2-format-chips__alert__close"
                            size="small"
                            onClick={() => {
                                removeFormatSelection([...selectedFormats]);
                            }}>
                            <Icon fontSize="inherit">close</Icon>
                        </IconButton>
                    </div>
                    <div className="ui-v2-format-chips__selected-formats">
                        {newSelectedFormats.map((selectedFormat) => (
                            <Tooltip key={selectedFormat.key} title={selectedFormat.title}>
                                <div className="ui-v2-format-chips__container__chip">
                                    <span>{selectedFormat.title}</span>
                                    <Icon className="ui-v2-format-chips__container__chip__deselect" onClick={() => removeFormatSelection([selectedFormat.key])}>
                                        cancel
                                    </Icon>
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default FormatChips;
