import classNames from 'classnames';
import React, { useEffect } from 'react';
import Badge from 'components/ui-components-v2/Badge';
import Icon from 'components/ui-components-v2/Icon';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CommentsHelpers } from 'helpers/comments.helpers';
import './styles/main.scss';

interface Props {
    className?: string;
    dataTourSelector?: string;
    active: boolean;
    // The entity for which the comments are fetched.
    entity: string;
    // The link parameter for fetching comments.
    link?: string;
    onClick: () => void;
}

interface ComponentStoreCommentsProps {
    comments: Comment[];
}

const CommentButton = ({ className, dataTourSelector, active, entity, link, onClick }: Props): JSX.Element => {
    const { comments } = useComponentStore<ComponentStoreCommentsProps>('Comments', {
        fields: {
            comments: entity
        }
    });

    useEffect(() => {
        CommentsHelpers.fetchComments(entity, undefined, link, true);
        return () => {
            CommentsHelpers.cleanUpComments(entity);
        };
    }, []);

    return (
        <Tooltip title={Translation.get('comments.comments', 'common')}>
            <ToggleButton
                className={classNames('ui-v2-comments-button', className)}
                data-tour-selector={dataTourSelector}
                onChange={onClick}
                value={active}
                selected={active}
                color="primary">
                <Badge badgeContent={comments && comments.length} color="primary">
                    {active ? <Icon>chat</Icon> : <Icon>chat_bubble</Icon>}
                </Badge>
            </ToggleButton>
        </Tooltip>
    );
};

export { CommentButton };
